import {
    Digits,
    Group, GroupShadow1, GroupShadow2,
} from "./styles.ts";
import {DigitItem} from "./DigitItem.tsx";
import {useAppSelector} from "../../../shared/lib/redux/useAppSelector.ts";
import {timeOverSelector} from "../../../features/Clock/store/selectors.ts";

interface IClockItem {
    digits: number
}

export const ClockItem = ({ digits }: IClockItem) => {
    const firstDigit = Math.trunc( digits / 10 )
    const secondDigit = digits % 10

    const timeOver = useAppSelector(timeOverSelector)

    return (
        <Digits>
            <Group>
                <DigitItem digit={firstDigit} timeOver={timeOver} />
                <DigitItem digit={secondDigit} timeOver={timeOver} />
            </Group>
            <GroupShadow1>
                <DigitItem digit={firstDigit} withShadow timeOver={timeOver} />
                <DigitItem digit={secondDigit} withShadow timeOver={timeOver} />
            </GroupShadow1>
            <GroupShadow2>
                <DigitItem digit={firstDigit} withShadow2 timeOver={timeOver} />
                <DigitItem digit={secondDigit} withShadow2 timeOver={timeOver} />
            </GroupShadow2>
        </Digits>
    )
}