import {Container, ContentWrapper, Envelope, LetterWrapper, Shadow} from "./styles.ts";
import PrizeJpg from '../../assets/white-noise.jpg'
import {useAppSelector} from "../../shared/lib/redux/useAppSelector.ts";
import {showPrizeSelector} from "../../features/Clock/store/selectors.ts";
import {useState} from "react";


export const Prize = () => {
    const showPrize = useAppSelector(showPrizeSelector)

    const [isOpen, setIsOpen] = useState(false)

    const openHandler = () => {
        setIsOpen(true)
    }

    const prizeClickHandler = () => {
        console.info("prize")
    }

    if (!showPrize) {
        return null
    }

    return (
        <Container>
            <ContentWrapper>
                <Envelope onClick={openHandler} $isOpen={isOpen} />
                <LetterWrapper $isOpen={isOpen} >
                    <img onClick={prizeClickHandler} height={'100%'} src={PrizeJpg} alt={''}/>
                </LetterWrapper>
                <Shadow />
            </ContentWrapper>
        </Container>
    )
}