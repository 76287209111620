import {createSlice} from "@reduxjs/toolkit";
import {INITIAL_STATE} from "./initialState.ts";
import {AMOUNT_WORDS} from "../../../components/FiveLettersGame/constants.ts";
import {filteredSearchWord, getLettersInfo} from "./utils.ts";

export const FIVE_LETTERS_GAME_REDUCER = 'fiveLettersGame'

const FiveLettersGameSlice = createSlice({
    name: FIVE_LETTERS_GAME_REDUCER,
    initialState: INITIAL_STATE,
    reducers: {
        setActiveLetter: (state, action) => {
            const { activeLetter } = action.payload
            state.activeLetter = activeLetter
        },
        setWrongLanguage: (state, action) => {
            state.wrongLanguage = action.payload
            state.wrongWord = null
        },
        setWrongWord: (state, action) => {
            state.wrongWord = action.payload
            state.wrongLanguage = false
        },
        fillingWord: (state, action) => {
            const { number, letter } = action.payload
            state.wrongLanguage = false
            state.wrongWord = null
            state.letters[number - 1] = letter
            state.clearWord = false
            state.start = false
        },
        clearWord: (state) => {
            state.letters = []
            state.activeLetter = 1
            state.clearWord = true
        },
        acceptWord: (state, action) => {
            const { word } = action.payload
            const { searchWord, letters } = state
            const lettersArray = getLettersInfo(searchWord, letters)
            state.filledLetters = [...state.filledLetters, ...lettersArray]
            state.words.push({ word, filled: true, letters: lettersArray })
            if (word === searchWord) {
                state.isWin = true
                state.winnerWords.push(state.searchWord)
            } else if (state.attempt === AMOUNT_WORDS) {
                state.isFailed = true;
            } else {
                state.attempt += 1
                state.activeLetter = 1
                state.letters = []
            }
        },
        nextWord: (state) => {
            state.attempt = 1;
            state.activeLetter = 1;
            state.letters = [];
            state.words = [];
            state.start = true;
            state.isWin = false;
            state.filledLetters = [];
            state.searchWord = filteredSearchWord(state.winnerWords);
        },
        restart: (state) => {
            state.attempt = 1;
            state.activeLetter = 1;
            state.letters = [];
            state.words = [];
            state.start = true;
            state.isWin = false;
            state.isFailed = false;
            state.filledLetters = [];
            state.searchWord = filteredSearchWord(state.winnerWords);
        },
    }
})

export const fiveLettersGameReducer = FiveLettersGameSlice.reducer
export const fiveLettersGameActions = FiveLettersGameSlice.actions

export const {
    setActiveLetter,
    setWrongLanguage,
    setWrongWord,
    fillingWord,
    acceptWord,
    clearWord,
    nextWord,
    restart,
} = fiveLettersGameActions