import {ClockWrapper, MainWrapper, StartButton} from './styles.ts';
import {ClockItem} from "./ui/ClockItem.tsx";
import {useEffect, useState} from "react";
import {ColonItem} from "./ui/ColonItem.tsx";

interface IClockProps {
    setClickCount: (clickCount: number) => void
    clickCount: number
}

const BIRTHDAY = new Date("2025-01-27T00:24:00")

export const Clock = ({ setClickCount, clickCount }: IClockProps) => {
    const [time, setTime] = useState(new Date())
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    const [timeOver, setTimeOver] = useState(false)

    useEffect(() => {
        const timer = setInterval(() => setTime(new Date()), 1000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        const timeDiff = BIRTHDAY.getTime() - time.getTime()

        if (timeDiff === 0 || timeDiff < 0) {
            setHours(0)
            setMinutes(0)
            setSeconds(0)
            setTimeOver(true)
        } else {

            const dirtyHours = timeDiff / (60 * 60 * 1000)
            const hours = Math.trunc(dirtyHours)

            const dirtyMinutes = (dirtyHours - hours) * 60
            const minutes = Math.trunc(dirtyMinutes)

            const seconds = Math.trunc((dirtyMinutes - minutes) * 60)

            setHours(hours)
            setMinutes(minutes)
            setSeconds(seconds)
        }
    }, [time])

    const clickHandler = () => {
        setClickCount(clickCount + 1)
    }

  return (
      <ClockWrapper>
          <MainWrapper $timeOver={timeOver} >
              {timeOver && <StartButton onClick={clickHandler} >GO!  GO!  GO!</StartButton>}
              <ClockItem digits={hours} timeOver={timeOver} />
              <ColonItem/>
              <ClockItem digits={minutes} timeOver={timeOver} />
              <ColonItem/>
              <ClockItem digits={seconds} timeOver={timeOver} />
          </MainWrapper>
      </ClockWrapper>
  )
};
