import {createBaseSelector} from "../../../shared/lib/redux/createBaseSelector.ts";
import {IClockState} from "./types.ts";
import {CLOCK_REDUCER} from "./reducer.ts";
import {createSelector} from "@reduxjs/toolkit";

export const clockBaseSelector = createBaseSelector<IClockState>(CLOCK_REDUCER)

export const showBannerSelector = createSelector(
    [clockBaseSelector],
    (state) => state.showBanner
)

export const showGameSelector = createSelector(
    [clockBaseSelector],
    (state) => state.showGame
)

export const showClockSelector = createSelector(
    [clockBaseSelector],
    (state) => state.showClock
)

export const timeOverSelector = createSelector(
    [clockBaseSelector],
    (state) => state.timeOver
)

export const showPrizeSelector = createSelector(
    [clockBaseSelector],
    (state) => state.showPrize
)