import styled, {keyframes} from "styled-components";

const letterAnimate = keyframes`
    50% {
        scale: 1.5;
    }
`

const trackingInContractBck = keyframes`
    0% {
        letter-spacing: 1em;
        transform: translateZ(400px);
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        transform: translateZ(0);
        opacity: 1;
    }
`

export const TitleWrapper = styled.div`
    position: relative;
    font-size: 5rem;
    padding: 6rem 0 1rem;
    width: 100vw; //min(14ch, 100vw);
    margin: 0 auto;
    perspective: 1000px;
    color: yellow;
`

export const TitleH1 = styled.h1`
    position: relative;
    font-size: .15rem;
    text-align: center;
    animation: ${trackingInContractBck} 6s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
`

export const TitleLetterSpan = styled.span.attrs<{ $i: number }>({})`
    display: inline-block;
    font-size: 5rem;
    white-space: pre;
    animation: 
            ${letterAnimate} 
            calc(3s / 5)
            calc(-2s + ${({ $i }) => `${$i} * 100ms`}) 
            ease-in-out 
            infinite;
`

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 30px;
`

export const ContentWrapper = styled.div`
    border: 1px solid yellow;
    padding: 8px;
    font-size: 1.2rem;
`

export const ContentParagraph = styled.p`
    text-indent: 50px;
    text-align: center;
    letter-spacing: 3px;
    line-height: 50px;
`

export const GoToPrizeWrapper = styled.div`
    width: 100%;
    border: 1px solid red;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 33, 175, 0.5);
    cursor: pointer;
`