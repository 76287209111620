import {ContentParagraph, ContentWrapper, MainWrapper} from "./styles.ts";
import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {GoToPrize} from "./GoToPrize.tsx";

export const Content = () => {

    return (
        <MainWrapper>
            <ContentWrapper>
                <ContentParagraph>
                    Дорогой друг! Поздравляю, ты прожил еще год! Желаю тебе оставаться всегда таким же Жизнерадостным, как усы димы усова,
                    а так же Отзывчивым, Предприимчивым и Активным, как **** игоря!
                </ContentParagraph>
            </ContentWrapper>
            <ContentWrapper>
                <ContentParagraph>
                    Несколько полезных ссылок:
                </ContentParagraph>
                <List>
                    <ListItemButton href={'https://prodoctorov.ru/spb/otorinolaringolog/'} target='_blank'>
                        <ListItemIcon>
                            🔥
                        </ListItemIcon>
                        <ListItemText primary="Хорошо провести время" />
                    </ListItemButton>
                    <ListItemButton href={'https://www.tbank.ru/invest/stocks/YDEX/'} target='_blank'>
                        <ListItemIcon>
                            💸
                        </ListItemIcon>
                        <ListItemText primary="Where is my money?" />
                    </ListItemButton>
                    <ListItemButton href={'https://www.binance.com/ru/price/bitcoin'} target='_blank'>
                        <ListItemIcon>
                            💰
                        </ListItemIcon>
                        <ListItemText primary="Reach beach" />
                    </ListItemButton>
                    <ListItemButton href={'https://www.hpiracing.com/en/videos'} target='_blank'>
                        <ListItemIcon>
                            🍆
                        </ListItemIcon>
                        <ListItemText primary="Oh yeah babe" />
                    </ListItemButton>
                </List>
            </ContentWrapper>
            <GoToPrize/>
        </MainWrapper>
    )
}