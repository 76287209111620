import {WordWrapper} from "./styles.ts";
import {Letter} from "../Letter/Letter.tsx";
import {useAppSelector} from "../../../../shared/lib/redux/useAppSelector.ts";
import {
    activeLetterSelector,
    isStartSelector,
    wordsSelector
} from "../../../../features/FiveLettersGame/store/selectors.ts";
import {useAppDispatch} from "../../../../shared/lib/redux/useAppDispatch.ts";
import {fillingWord, setActiveLetter} from "../../../../features/FiveLettersGame/store/reducer.ts";

interface IWordProps {
    wordNumber: number
    isActiveWord: boolean
}

export const Word = ({ wordNumber, isActiveWord }: IWordProps) => {
    const activeLetter = useAppSelector(activeLetterSelector)
    const words = useAppSelector(wordsSelector)
    const isStart = useAppSelector(isStartSelector)

    const dispatch = useAppDispatch()

    const LETTERS = [1, 2, 3, 4, 5];

    const getNextLetter = (current: number) => {
        const next = current + 1

        if (next > LETTERS.length) {
            return LETTERS[LETTERS.length - 1]
        }

        return next
    }

    const setFilled = (number: number, letter: string) => {
        const nextLetter = getNextLetter(number)
        dispatch(setActiveLetter({ activeLetter: nextLetter }))
        dispatch(fillingWord({ number, letter }))
    }

    return (
        <WordWrapper>
            {LETTERS.map((item, index) => (
                <Letter
                    key={index}
                    number={index + 1}
                    wordNumber={wordNumber}
                    isActiveLetter={activeLetter === index + 1}
                    isActiveWord={isActiveWord}
                    setFilled={setFilled}
                    isStart={isStart}
                />
            ))}
        </WordWrapper>
    )
}