import styled from "styled-components";

export const LetterWrapper = styled.div.attrs<{ $disabled: boolean, $isRight: boolean, $isOtherOrder: boolean }>({})`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    padding: 10px;
    border: 2px solid ${({ $disabled }) => $disabled ? '#747bff' : 'transparent'};
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: ${({ $disabled, $isRight, $isOtherOrder }) => 
            $disabled ? '#3C3744' 
            : $isRight ? '#747bff' 
            : $isOtherOrder ? '#F67E7D' 
            : '#FBFFF1'};
    
    ${({ $disabled, $isRight }) => ($disabled || $isRight) && 'pointer-events: none;'}
    
`

export const LetterInput = styled.input.attrs<{ $isRight: boolean, $isOtherOrder: boolean }>({})`
    width: 100%;
    background-color: transparent;
    user-select: none;
    outline: none;
    text-align: center;
    color: ${({ $isRight, $isOtherOrder }) => $isRight || $isOtherOrder ? '#FBFFF1' : '#3C3744'};
    caret-color: #3C3744;
    border: none;
    font-size: 45px;
    font-weight: 800;
    
    ${({ $isRight }) => $isRight && 'pointer-events: none;'}
`