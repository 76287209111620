import {FIVE_LETTERS_GAME_REDUCER, fiveLettersGameReducer} from "../features/FiveLettersGame/store/reducer.ts";
import {combineReducers, configureStore} from "@reduxjs/toolkit";

export const reducers = {
    [FIVE_LETTERS_GAME_REDUCER]: fiveLettersGameReducer
}

export const rootReducer = combineReducers(reducers)

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>

export type AppDispatch = typeof store.dispatch;