import styled from "styled-components";

export const BannerWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    background-image: url('https://media.giphy.com/media/U3qYN8S0j3bpK/giphy.gif'); //url(https://assets.codepen.io/25387/stars.gif);
    color: lightgoldenrodyellow;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
`

export const ContentWrapper = styled.div`
    width: 100%;
    margin-left: 30px;
    display: flex;
    gap: 30px;
    //justify-content: start;
`