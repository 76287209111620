import {createTheme} from "@mui/material";

export const muiThemeConfig = createTheme({
    components: {
        MuiListItemText: {
            styleOverrides: {
                root: {
                    fontSize: '10rem',
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '2rem',
                }
            }
        }
    }
})