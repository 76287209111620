import styled from "styled-components";

export const GridWrapper = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: center;
`

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
`

export const ButtonWrapper = styled(GridWrapper)`
    margin: 20px 0;
`

export const MessageWrapper = styled.div`
    margin: 10px 0;
`

export const ErrorMessage = styled.span`
    font-family: monospace;
    color: #D72638;
`
