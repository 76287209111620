import {LogoLetter, LogoWrapper} from "./styles.ts";
import {LOGO} from "../../constants.ts";

export const Logo = () => {

    return (
        <LogoWrapper>
            {LOGO.split('').map((letter, index) => (
                <LogoLetter key={index}>{letter}</LogoLetter>
            ))}
        </LogoWrapper>
    )
}