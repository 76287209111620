import {useEffect, useState} from 'react';
import './App.css';
import {Clock} from "../components/Clock/Clock.tsx";
import {FiveLettersGame} from "../components/FiveLettersGame/FiveLettersGame.tsx";
import {AppStore} from "./providers/AppStore.tsx";
import GlobalFonts from '../assets/fonts/fonts.ts'
import {Banner} from "../components/Banner/Banner.tsx";

function App() {
  const [clickCount, setClickCount] = useState(0)
    const [showGame, setShowGame] = useState(false)

    useEffect(() => {
        if (clickCount === 3) {
            setShowGame(true)
        }
    }, [clickCount])

  return (
      <AppStore>
          <GlobalFonts />
          { showGame
              ? <FiveLettersGame />
              : <Clock clickCount={clickCount} setClickCount={setClickCount}/> }
      </AppStore>
  );
}

export default App;
