import {LetterGrid} from "./ui/LettersGrid/LetterGrid.tsx";
import {Logo} from "./ui/Logo/Logo.tsx";
import {CustomBackground, GameWrapper} from "./ui/styles.ts";
import {FilledLetters} from "./ui/FilledLetters/FilledLetters.tsx";
import {useAppSelector} from "../../shared/lib/redux/useAppSelector.ts";
import {showGameSelector} from "../../features/Clock/store/selectors.ts";

export const FiveLettersGame = () => {
    const showGame = useAppSelector(showGameSelector)

    if (!showGame) {
        return null
    }

    return (
        <CustomBackground>
            <GameWrapper>
                <Logo />
                <FilledLetters />
                <LetterGrid />
            </GameWrapper>
        </CustomBackground>
    )
}