import {AMOUNT_WORDS} from "../../constants.ts";
import {ButtonWrapper, Container, ErrorMessage, GridWrapper, MessageWrapper} from "./styles.ts";
import {Word} from "../Word/Word.tsx";
import {Button} from "@mui/material";
import {useAppSelector} from "../../../../shared/lib/redux/useAppSelector.ts";
import {
    attemptSelector, isFailedSelector, isWinSelector,
    lettersSelector, searchWordSelector, winnerWordsSelector,
    wrongLanguageSelector, wrongWordSelector
} from "../../../../features/FiveLettersGame/store/selectors.ts";
import {useAppDispatch} from "../../../../shared/lib/redux/useAppDispatch.ts";
import wordsDict from '../../data/words.json'
import {
    acceptWord,
    clearWord,
    nextWord,
    restart,
    setWrongWord
} from "../../../../features/FiveLettersGame/store/reducer.ts";
import {FormEvent} from "react";
import {ConfettiCongrats} from "../ConfettiCongrats/ConfettiCongrats.tsx";
import {setShowGame, setShowPrize} from "../../../../features/Clock/store/reducer.ts";

export const LetterGrid = () => {
    const attempt = useAppSelector(attemptSelector)
    const letters = useAppSelector(lettersSelector)
    const wrongLanguage = useAppSelector(wrongLanguageSelector)
    const wrongWord = useAppSelector(wrongWordSelector)
    const isWin = useAppSelector(isWinSelector)
    const searchWord = useAppSelector(searchWordSelector)
    const isFailed = useAppSelector(isFailedSelector)
    const winnerWords = useAppSelector(winnerWordsSelector)

    const dispatch = useAppDispatch()

    const { available } = wordsDict

    const handleAcceptWord = () => {
        const word = letters.join('')

        const isExistWord = !!available.find((item) => item === word)
        if (!isExistWord) {
            dispatch(setWrongWord(word))
            dispatch(clearWord())
        } else {
            dispatch(acceptWord({ word }))
        }
    }

    const handleNextWord = () => dispatch(nextWord())

    const handleRestart = () => dispatch(restart())

    const acceptButtonIsDisabled = letters.length < 5

    const WORDS = Array.from({ length: AMOUNT_WORDS }, (_, i) => i + 1)

    const submitHandleForm = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (isWin && winnerWords.length < 3) {
            return handleNextWord();
        }

        //if (isWin && winnerWords.length === 3) {
        //    dispatch(setShowGame(false))
        //    dispatch(setShowPrize(true))
        //}

        if (isFailed) {
            return handleRestart();
        }

        return handleAcceptWord();
    }

    const getButtonText = () => {
        if (isFailed) {
            return 'Попробовать ещё раз'
        }

        if (isWin && winnerWords.length < 3) {
            return 'Next'
        }

        //if (isWin && winnerWords.length === 3) {
        //    return 'Забрать подарок'
        //}

        return 'OK?'
    }

    return (
        <Container onSubmit={submitHandleForm}>
            <ConfettiCongrats isWin={isWin} />
            <MessageWrapper>
                { wrongLanguage ? (
                    <ErrorMessage>in Russian please</ErrorMessage>
                ) : null }
                { wrongWord ? (
                    <ErrorMessage><b>{wrongWord}</b>? Серьезно? Подумай ещё.</ErrorMessage>
                ) : null }
                { isFailed ? (
                    <ErrorMessage>NO GOD PLEASE NO! Try again....</ErrorMessage>
                ) : null }
            </MessageWrapper>
            <GridWrapper>
                {WORDS.map((item, index) => (
                    <Word
                        key={index}
                        wordNumber={index + 1}
                        isActiveWord={attempt === index + 1}
                    />
                ))}
            </GridWrapper>
            <ButtonWrapper>
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="secondary"
                >
                    {getButtonText()}
                </Button>
            </ButtonWrapper>
        </Container>
    )
}