import {LetterInput, LetterWrapper} from "./styles.ts"
import React, {Ref, KeyboardEvent, useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../../../shared/lib/redux/useAppSelector.ts";
import {clearWordSelector, isWinSelector, wordsSelector} from "../../../../features/FiveLettersGame/store/selectors.ts";
import {useAppDispatch} from "../../../../shared/lib/redux/useAppDispatch.ts";
import {setActiveLetter, setWrongLanguage} from "../../../../features/FiveLettersGame/store/reducer.ts";
import {LetterOtherOrder, LetterRightOrder} from "../../../../features/FiveLettersGame/store/types.ts";

interface ILetterProps {
    number: number
    wordNumber: number
    setFilled: (number: number, letter: string) => void
    isActiveLetter: boolean
    isActiveWord: boolean
    isStart: boolean
}

export const Letter = ({
   number,
   wordNumber,
   setFilled,
   isActiveLetter,
   isActiveWord,
   isStart,
}: ILetterProps) => {
    const [letter, setLetter] = useState<string>('')

    const ref: Ref<HTMLInputElement> = useRef(null)
    const clearWord = useAppSelector(clearWordSelector)
    const words = useAppSelector(wordsSelector)
    const isWin = useAppSelector(isWinSelector)

    const dispatch = useAppDispatch()

    const acceptedLetters = words[wordNumber - 1]?.letters || {}

    useEffect(() => {
        if (isStart) {
            setLetter('')
        }
    }, [isStart])

    useEffect(() => {
        // задаем focus
        if (isActiveLetter && isActiveWord) {
            ref?.current?.focus()
        }
    }, [isActiveLetter])

    useEffect(() => {
        // очищаем focus
        if (isWin && isActiveWord) {
            ref?.current?.blur();
        }
    }, [isWin])

    useEffect(() => {
        if (clearWord && isActiveWord) {
            setLetter('')
        }
    }, [clearWord])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const isRussianSymbol = /[а-яА-ЯЁё]/.test(value)
        if (value) {
            if (isRussianSymbol) {
                const upperCaseValue = value.toUpperCase()
                setLetter(upperCaseValue)
                setFilled(number, upperCaseValue)
            } else {
                dispatch(setWrongLanguage(true))
            }
        }
    }

    const handleChangeLetter = () => {
        dispatch(setActiveLetter({ activeLetter: number }));
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const code = e.code

        if (letter && isActiveLetter) {
            dispatch(setActiveLetter({ activeLetter: number + 1 }))
        }

        if (!isWin && code === 'Backspace') {
            setLetter('')
            dispatch(setActiveLetter({ activeLetter: number - 1 }))
        }

        if (code === 'Tab' || code === 'ArrowRight') {
            e.preventDefault()

            dispatch(
                setActiveLetter({ activeLetter: number === 5 ? 5 : number + 1 })
            )
        }

        if (code === 'ArrowLeft') {
            dispatch(
                setActiveLetter({ activeLetter: number === 1 ? 1 : number - 1 })
            )
        }
    }

    const isRight = () => {
        if (letter && acceptedLetters.length) {
            const find = acceptedLetters.find(
                ({
                     number: acceptedNumber,
                     letter: acceptedLetter,
                     isRight,
                 }: LetterRightOrder) =>
                    acceptedLetter === letter && acceptedNumber === number && isRight
            );

            return !!find
        }

        return false
    }

    const isOtherOrder = () => {
        if (letter && acceptedLetters.length) {
            const find = acceptedLetters.find(
                ({
                     number: acceptedNumber,
                     letter: acceptedLetter,
                     isOtherOrder,
                 }: LetterOtherOrder) =>
                    acceptedLetter === letter && acceptedNumber === number && isOtherOrder
            );

            return !!find
        }

        return false
    }

    return (
        <LetterWrapper
            onClick={handleChangeLetter}
            $disabled={!isActiveWord && wordNumber > words.length}
            $isRight={isRight()}
            $isOtherOrder={isOtherOrder()}
        >
            <LetterInput
                ref={ref}
                maxLength={1}
                onChange={handleInput}
                value={letter}
                onFocus={() => !clearWord && ref?.current?.select()}
                onKeyDown={handleKeyDown}
                $isRight={isRight()}
                $isOtherOrder={isOtherOrder()}
            />
        </LetterWrapper>
    )
}