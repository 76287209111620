import {
    DigitFigure,
    EndBottomSpan,
    EndTopSpan,
    MiddleSpan,
    SideBottomLeftSpan,
    SideBottomRightSpan,
    SideTopLeftSpan,
    SideTopRightSpan
} from "./styles.ts";
import {digitMatrix} from "./utils.ts";

interface IDigitItem {
    digit: number
    withShadow?: boolean
    withShadow2?: boolean
    timeOver: boolean
}

export const DigitItem = ({ digit, withShadow = false, withShadow2 = false, timeOver }: IDigitItem) => {

    const actArray = digitMatrix[digit]

    return (
        <DigitFigure $withShadow={withShadow} $withShadow2={withShadow2}>
            <EndTopSpan $act={actArray[0]} $withShadow={withShadow} $withShadow2={withShadow2} $timeOver={timeOver} />
            <SideTopLeftSpan $act={actArray[1]} $withShadow={withShadow} $withShadow2={withShadow2} $timeOver={timeOver} />
            <SideTopRightSpan $act={actArray[2]} $withShadow={withShadow} $withShadow2={withShadow2} $timeOver={timeOver} />
            <MiddleSpan $act={actArray[3]} $withShadow={withShadow} $withShadow2={withShadow2} $timeOver={timeOver} />
            <SideBottomLeftSpan $act={actArray[4]} $withShadow={withShadow} $withShadow2={withShadow2} $timeOver={timeOver} />
            <SideBottomRightSpan $act={actArray[5]} $withShadow={withShadow} $withShadow2={withShadow2} $timeOver={timeOver} />
            <EndBottomSpan $act={actArray[6]} $withShadow={withShadow} $withShadow2={withShadow2} $timeOver={timeOver} />
        </DigitFigure>
    )
}