import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {INITIAL_STATE} from "./initialState.ts";

export const CLOCK_REDUCER = 'clock'

const ClockSlice = createSlice({
    name: CLOCK_REDUCER,
    initialState: INITIAL_STATE,
    reducers: {
        setShowBanner: ( state, action: PayloadAction<boolean> ) => {
            state.showBanner = action.payload
        },
        setShowGame: ( state, action: PayloadAction<boolean> ) => {
            state.showGame = action.payload
        },
        setShowClock: ( state, actions: PayloadAction<boolean> ) => {
            state.showClock = actions.payload
        },
        setShowPrize: ( state, actions: PayloadAction<boolean> ) => {
            state.showPrize = actions.payload
        },
        setTimeOver: ( state, action: PayloadAction<boolean> ) => {
            state.timeOver = action.payload
        }
    }
})

export const clockReducer = ClockSlice.reducer
export const clockActions = ClockSlice.actions

export const {
    setShowBanner,
    setShowGame,
    setShowClock,
    setShowPrize,
    setTimeOver,
} = clockActions