import {BannerWrapper, ContentWrapper} from "./styles.ts";
import {MainTitle} from "./ui/MainTitle.tsx";
import {Content} from "./ui/Content.tsx";
import ScoobertGif from '../../assets/scoobert-mohu.gif'
import {useAppSelector} from "../../shared/lib/redux/useAppSelector.ts";
import {showBannerSelector} from "../../features/Clock/store/selectors.ts";

export const Banner = () => {
    const showBanner = useAppSelector(showBannerSelector)

    if (!showBanner) {
        return null
    }

    return (
        <BannerWrapper>
            <MainTitle />
            <ContentWrapper>
                <figure style={{ marginTop: '150px' }}>
                    <img src={ScoobertGif} alt={''}/>
                </figure>
                <Content/>
                <figure style={{ marginTop: '350px' }}>
                    <img
                        src={'https://gifs.obs.ru-moscow-1.hc.sbercloud.ru/68cd0483c05767c22564f87b6ddd35e51aff8f5ef7c3c723d85f3eb5cc2236d3.gif'}
                        alt={''}/>
                </figure>
            </ContentWrapper>
        </BannerWrapper>
    )
}