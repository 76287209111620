import {createBaseSelector} from "../../../shared/lib/redux/createBaseSelector.ts";
import {IFiveLettersGameState} from "./types.ts";
import {FIVE_LETTERS_GAME_REDUCER} from "./reducer.ts";
import {createSelector} from "@reduxjs/toolkit";


export const fiveLettersGameBaseSelector = createBaseSelector<IFiveLettersGameState>(FIVE_LETTERS_GAME_REDUCER)

export const attemptSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.attempt
)

export const lettersSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.letters
)

export const wrongLanguageSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.wrongLanguage
)

export  const wrongWordSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.wrongWord
)

export const isWinSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.isWin
)

export const isFailedSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.isFailed
)

export const searchWordSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.searchWord
)
export const activeLetterSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.activeLetter
)

export const isStartSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.start
)

export const wordsSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.words
)

export const clearWordSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.clearWord
)

export const filledLettersSelector = createSelector(
    [fiveLettersGameBaseSelector],
    (state) => state.filledLetters
)