import styled from "styled-components";

export const FilledLettersWrapper = styled.div`
    position: absolute;
    right: 20px;
    top: 30px;
    padding: 10px;
    max-width: 210px;
`

export const UlStyled = styled.ul`
    display: flex;
    flex-flow: row wrap;
    list-style: none;
`

export const LiStyled = styled.li.attrs<{ $isMiss: boolean, $isOther: boolean, $isRight: boolean }>({})`
    cursor: default;
    color: ${({ $isMiss, $isOther, $isRight }) => 
            $isMiss ? '#190f01' 
            : $isOther ? '#FBFFF1' 
            : $isRight ? '#FBFFF1' :'white'};
    width: 30px;
    height: 30px;
    background-color: ${({ $isMiss, $isOther, $isRight }) =>
            $isMiss ? '#FBFFF1'
                    : $isOther ? '#F67E7D'
                            : $isRight ? '#747bff' : '#3C3744'};;
    padding: 3px 6px;
    font-size: 14px;
    margin-right: 8px;
    border-radius: 4px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px;
    text-align: center;
    
    ${({ $isMiss }) => $isMiss && 'opacity: 0.15;'}
`