import { createGlobalStyle } from 'styled-components'

import VT323Font from './VT323-Regular.ttf'

export default createGlobalStyle`
    @font-face {
        font-family: 'VT323';
        font-weight: normal;
        font-style: normal;
        font-display: swap;
        src: url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
    }
`