import {
    Digits,
    Group, GroupShadow1, GroupShadow2,
} from "./styles.ts";
import {DigitItem} from "./DigitItem.tsx";
import {useAppSelector} from "../../../shared/lib/redux/useAppSelector.ts";
import {timeOverSelector} from "../../../features/Clock/store/selectors.ts";
import {useEffect, useState} from "react";

interface IClockItem {
    digits: number
}

export const ClockItem = ({ digits }: IClockItem) => {
    const [digitsArray, setDigitsArray] = useState<number[]>([])

    useEffect(() => {
        let newDigitsArray = digits.toString().split('').map((item) => Number(item))
        if (newDigitsArray.length === 1) {
            newDigitsArray = [0, ...newDigitsArray]
        }

        setDigitsArray(newDigitsArray)
    }, [digits])

    const timeOver = useAppSelector(timeOverSelector)

    return (
        <Digits>
            <Group>
                {digitsArray.map((digit) => (
                    <DigitItem digit={digit} timeOver={timeOver} />
                ))}
            </Group>
            <GroupShadow1>
                {digitsArray.map((digit) => (
                    <DigitItem digit={digit} timeOver={timeOver} />
                ))}
            </GroupShadow1>
            <GroupShadow2>
                {digitsArray.map((digit) => (
                    <DigitItem digit={digit} timeOver={timeOver} />
                ))}
            </GroupShadow2>
        </Digits>
    )
}