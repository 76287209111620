import {TitleH1, TitleLetterSpan, TitleWrapper} from "./styles.ts";

const TITLE = 'Welcome to 38 years old!'

export const MainTitle = () => {
    return (
        <TitleWrapper>
            <TitleH1>
                {TITLE.split('').map((letter, index) => (
                    <TitleLetterSpan key={index} $i={index}>{letter}</TitleLetterSpan>
                ))}
            </TitleH1>
        </TitleWrapper>
    )
}