import { createGlobalStyle } from 'styled-components'

import VT323 from './VT323-Regular.ttf'

export default createGlobalStyle`
    @font-face {
        font-family: 'VT323';
        src: local('VT323'), url(${VT323}) format('ttf');
        font-weight: 300;
        font-style: normal;
    }
`