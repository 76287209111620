import styled from "styled-components"
import {SpanProps} from "./types.ts";

export const Digits = styled.div`
    transform-style: preserve-3d;
`

export const Group = styled.div`
    display: flex;
    gap: 2rem;
`

export const GroupShadow = styled(Group)`
    top: 0;
    position: absolute;
    transform-origin: bottom center;
    transform: translateY(1rem) translateZ(2rem) rotateX(-90.1deg);
`

export const GroupShadow1 = styled(GroupShadow)`
    opacity: 0.3;
    filter: drop-shadow(0px 0px 4px currentColor)
    drop-shadow(0px 0px 10px currentColor) blur(4rem);
`

export const GroupShadow2 = styled(GroupShadow)`
    opacity: 0.4;
    filter: drop-shadow(0px 0px 4px currentColor)
    drop-shadow(0px 0px 10px currentColor) blur(4px);
`

export const DigitFigure = styled.figure.attrs<{ $withShadow: boolean, $withShadow2: boolean }>({})`
    position: relative;
    height: 16rem;
    aspect-ratio: 1/2;
    filter: drop-shadow(0px 0px 4px currentColor) drop-shadow(0px 0px 10px currentColor);
    opacity: 1;
    
    ${({ $withShadow, $withShadow2 }) => $withShadow 
            ? 'mask-image: linear-gradient(to bottom, white, rgba(0, 0, 0, 0.5));' 
            : $withShadow2 ? 'mask-image: linear-gradient(to top, black, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0))' : ''}
    
`

const StyledSpan = styled.span.attrs<SpanProps>({
    $signY: 1,
    $signX: 1,
})`
    background-color: ${({ $timeOver }) => $timeOver ? '#D81E5B' : 'yellow' };
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.5, 1.15);
    opacity: ${({ $act, $withShadow2 }) => 
            typeof($act) != 'undefined' && $withShadow2 
                    ? $act 
                    : typeof($act) != 'undefined' ? 0.03 + 0.97 * $act : 0 } ;
    animation-composition: add;
    transform: scale(${({ $signX }) => $signX }, ${({ $signY }) => $signY } );
    position: absolute;
    
    ${({ $withShadow, $withShadow2 }) => $withShadow
            ? 'mask-image: linear-gradient(to bottom, white, rgba(0, 0, 0, 0.5));'
            : $withShadow2 ? 'mask-image: linear-gradient(to top, black, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0))' : ''}}
    
`

export const EndSpan = styled(StyledSpan)`
    clip-path: polygon(15% 0%, 7.5% 20%, 25% 100%, 75% 100%, 92.5% 20%, 85% 0%);
    width: 100%;
    height: 10%;
`

const SideSpan = styled(StyledSpan)`
    clip-path: polygon(0% 15%, 20% 7.5%, 100% 22.5%, 100% 85%, 20% 95%, 0% 90%);
    height: 50%;
    width: 20%;
`

export const EndTopSpan = styled(StyledSpan)`
    clip-path: polygon(15% 0%, 7.5% 20%, 25% 100%, 75% 100%, 92.5% 20%, 85% 0%);
    width: 100%;
    height: 10%;
    top: 0;
`

export const EndBottomSpan = styled(EndSpan).attrs({ $signY: -1 })`
    top: initial;
    bottom: 0;
`

export const SideTopLeftSpan = styled(SideSpan)`
    top: 0;
    left: 0;
`

export const SideTopRightSpan = styled(SideSpan).attrs({ $signX: -1 })`
    top: 0;
    left: initial;
    right: 0;
`

export const MiddleSpan = styled(StyledSpan)`
    clip-path: polygon(
            22.5% 0%,
            6.5% 50%,
            22.5% 100%,
            77.5% 100%,
            93.5% 50%,
            77.5% 0%
    );
    top: 45%;
    height: 10%;
    width: 100%;
`

export const SideBottomLeftSpan = styled(SideSpan).attrs({ $signY: -1 })`
    top: initial;
    bottom: 0;
    left: 0;
`

export const SideBottomRightSpan = styled(SideSpan).attrs({ $signY: -1, $signX: -1 })`
    top: initial;
    bottom: 0;
    left: initial;
    right: 0;
`

export const ColonGroup = styled.div`
    transform-style: preserve-3d;
`

const ColonFigureShadow = styled.figure`
    top: 0;
    position: absolute;
    transform-origin: bottom center;
    transform: translateY(1rem) translateZ(2rem) rotateX(-90.1deg);
`

export const ColonFigureShadow1 = styled(ColonFigureShadow)`
    opacity: 0.3;
    filter: drop-shadow(0px 0px 4px currentColor)
    drop-shadow(0px 0px 10px currentColor) blur(4rem);
`

export const ColonFigureShadow2 = styled(ColonFigureShadow)`
    opacity: 0.4;
    filter: drop-shadow(0px 0px 4px currentColor)
    drop-shadow(0px 0px 10px currentColor) blur(4px);
`

export const ColonSpan = styled.span`
    display: flex;
    height: 16rem;
    flex-direction: column;
    justify-content: space-evenly;
    width: 4rem;
    align-items: center;
    filter: drop-shadow(0px 0px 4px currentColor) drop-shadow(0px 0px 10px currentColor);
    
    &:before,
    &:after {
        content: "";
        display: block;
        width: 2rem;
        aspect-ratio: 1/1;
        background-color: white;
        border-radius: 2rem;
    }
`