import {IFiveLettersGameState} from "./types.ts";
import {availableWords} from "./utils.ts";

const possibleWords = ['ЛАТТЕ', 'ЗЕРНО', 'ЧАШКА']

export const INITIAL_STATE: IFiveLettersGameState = {
    words: [],
    attempt: 1,
    letters: [],
    start: true,
    activeLetter: 1,
    searchWord: possibleWords[Math.floor(Math.random() * possibleWords.length)],
    winnerWords: [],
    clearWord: false,
    wrongLanguage: false,
    wrongWord: null,
    isWin: false,
    isFailed: false,
    wordsCounter: 0,
    filledLetters: [],
}