import {RUSSIAN_ALPHABET} from "../../constants.ts";
import {useAppSelector} from "../../../../shared/lib/redux/useAppSelector.ts";
import {filledLettersSelector} from "../../../../features/FiveLettersGame/store/selectors.ts";
import {MarkedLetter} from "../../../../features/FiveLettersGame/store/types.ts";
import {FilledLettersWrapper, LiStyled, UlStyled} from "./styles.ts";

export const FilledLetters = () => {
    const alphabetSplit = RUSSIAN_ALPHABET.split('')
    const filledLetters = useAppSelector(filledLettersSelector)

    const markedLetters: MarkedLetter[] = []
    const existLetters: string[] = []

    alphabetSplit.forEach((letter) => {
        let isFilled = false
        filledLetters.forEach(
            ({
                 letter: filledLetter,
                 isMiss,
                 isOtherOrder,
                 isRight,
             }: MarkedLetter) => {
                if (letter === filledLetter && !existLetters.includes(letter)) {
                    markedLetters.push({ letter, isMiss, isOtherOrder, isRight })
                    existLetters.push(letter);
                    isFilled = true
                }
            }
        )

        if (!isFilled) {
            markedLetters.push({ letter })
        }
    })

    return (
        <FilledLettersWrapper>
            <UlStyled>
                {markedLetters.map(({ letter, isMiss, isOtherOrder, isRight }) => (
                    <LiStyled
                        key={letter}
                        $isMiss={Boolean(isMiss)}
                        $isRight={Boolean(isRight)}
                        $isOther={Boolean(isOtherOrder)}
                    >
                        {letter}
                    </LiStyled>
                ))}
            </UlStyled>
        </FilledLettersWrapper>
    )
}