import styled from "styled-components";

export const LogoWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
`

export const LogoLetter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 46px;
    border-radius: 6px;
    height: 80px;
    width: 80px;
    background-color: #747bff;
`