import './App.css';
import {Clock} from "../components/Clock/Clock.tsx";
import {FiveLettersGame} from "../components/FiveLettersGame/FiveLettersGame.tsx";
import {AppStore} from "./providers/AppStore.tsx";
import GlobalFonts from '../assets/fonts/fonts.ts'
import {Banner} from "../components/Banner/Banner.tsx";
import {ThemeProvider} from "@mui/material";
import {muiThemeConfig} from "./theme/theme.ts";
import {Prize} from "../components/Prize/Prize.tsx";

function App() {

  return (
      <AppStore>
          <GlobalFonts />
          <ThemeProvider theme={muiThemeConfig}>
              <FiveLettersGame />
              <Clock />
              <Banner />
              <Prize />
          </ThemeProvider>
      </AppStore>
  );
}

export default App;
