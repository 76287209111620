import {GoToPrizeWrapper} from "./styles.ts";
import {useAppDispatch} from "../../../shared/lib/redux/useAppDispatch.ts";
import {setShowBanner, setShowGame} from "../../../features/Clock/store/reducer.ts";

export const GoToPrize = () => {
    const dispatch = useAppDispatch()

    const clickHandler = () => {
        dispatch(setShowBanner(false))
        dispatch(setShowGame(true))
    }

    return (
        <GoToPrizeWrapper onClick={clickHandler}>
            <img height={'100px'} src={'https://gifs.obs.ru-moscow-1.hc.sbercloud.ru/79e0e02e8424c68e81b17b08c7946c0f23d43b5d7eaa9ab58190bc2977e28547.gif'}  alt={''}/>
        </GoToPrizeWrapper>
    )
}