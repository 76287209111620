import styled from 'styled-components';

export const ClockWrapper = styled.div`
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
`

export const MainWrapper = styled.main.attrs<{ $timeOver: boolean }>({})`
    display: flex;
    color: ${({ $timeOver }) => $timeOver ? 'hsla(341, 100%, 44%, 0.8)' : 'hsl(47.87deg, 94.68%, 63.14%)'};
    gap: 1rem;
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    animation-composition: add;
    translate: 0 1rem 10rem;
    transform-style: preserve-3d;
`

export const StartButton = styled.button`
    position: absolute;
    top: 160px;
    width: 300px;
    height: 100px;
    border: 2px solid #D81E5B;
    z-index: 100;
    background-color: transparent;
    font-family: 'VT323', monospace ;
    box-shadow: 4px 9px #999;
    outline: none;

    &:hover,
    &:focus,
    &:active
    {
        background-color: rgba(216, 30, 91, 0.7);
        border: 2px solid #D81E5B;
    }
    
    &:active {
        box-shadow: 2px 5px #666;
        transform: translateY(4px)
    }
`
