import {ColonFigureShadow1, ColonFigureShadow2, ColonGroup, ColonSpan} from "./styles.ts";

export const ColonItem = () => {
    return (
        <ColonGroup>
            <figure>
                <ColonSpan />
            </figure>
            <ColonFigureShadow1>
                <ColonSpan />
            </ColonFigureShadow1>
            <ColonFigureShadow2>
                <ColonSpan />
            </ColonFigureShadow2>
        </ColonGroup>
    )
}