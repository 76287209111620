import {LetterGrid} from "./ui/LettersGrid/LetterGrid.tsx";
import {Logo} from "./ui/Logo/Logo.tsx";
import {CustomBackground, GameWrapper} from "./ui/styles.ts";
import {FilledLetters} from "./ui/FilledLetters/FilledLetters.tsx";

export const FiveLettersGame = () => {
    return (
        <CustomBackground>
            <GameWrapper>
                <Logo />
                <FilledLetters />
                <LetterGrid />
            </GameWrapper>
        </CustomBackground>
    )
}